.panel {
  border: none !important;
  border-radius: 0 !important;
}

.panelBorder {
  border-bottom: 1Px solid #e0e0e0 !important;
}

.panelBody {
  border: none !important;
  border-radius: 0 !important;
}