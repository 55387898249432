.index {
  position: relative;
  height: 100%;
  background: #f5f5f5;
  box-sizing: border-box;
  padding-bottom: 0.466667rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.index .headerBar {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 2.346667rem;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0.533333rem 0.426667rem 0.24rem;
  z-index: 8000;
}

.index .headerBar .h-left,
.index .headerBar .h-right {
  font-size: 0;
  line-height: 0;
}

.index .headerBar .h-left {
  width: 3.213333rem;
  height: 1.013333rem;
  background: #fff url("../../assets/imgs/win/index/logo-sj.png") 0 0 no-repeat;
  background-size: 3.213333rem 1.013333rem;
}

.index .headerBar .h-right {
  width: 1.466667rem;
  height: 0.853333rem;
  background: #fff url("../../assets/imgs/win/index/icon-feedback-new-v2.png") 0 0 no-repeat;
  background-size: 1.466667rem 0.853333rem;
}

.index .card {
  margin-top: 0.266667rem;
  background: linear-gradient(180deg, #F1F3F9 0%, #FFFFFF 100%);
}

.index .card .card-box {
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  width: 9.653333rem;
  height: 5.466667rem;
  background: url("../../assets/imgs/win/index/i-card-bg-sj.png") 0 no-repeat;
  background-size: 9.653333rem 5.466667rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.8rem;
}

.index .card .c-header {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  height: 0.6rem;
  margin-bottom: 0.32rem;
}

.index .card .c-header h4 {
  font-size: 0.426667rem;
  color: #fff;
  line-height: 0.6rem;
  font-weight: 500;
  margin: 0;
}

.index .card .c-header div {
  margin-left: 0.32rem;
  box-sizing: border-box;
  padding-left: 0.48rem;
  background: url("../../assets/imgs/win/index/i-img-right-sj.png") 0 center no-repeat;
  background-size: 0.426667rem 0.426667rem;
  font-size: 0.32rem;
  color: #fff;
  opacity: 0.5;
  line-height: 0.44rem;
}

.index .card .c-center {
  box-sizing: border-box;
  padding: 0.426667rem 0 0.4rem;
}

.index .card .c-center .c-c-title {
  height: 0.44rem;
  line-height: 0.44rem;
  font-size: 0.32rem;
  opacity: 0.5;
  margin-bottom: 0.066667rem;
  color: #fff;
}

.index .card .c-center .c-c-body {
  height: 1.173333rem;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
}

.index .card .c-center .c-c-body .c-c-left {
  font-family: 'FontDIN';
  font-size: 0.96rem;
  line-height: 1.173333rem;
  color: #fff;
  font-weight: 500;
}

.index .card .c-center .c-c-body .c-c-right {
  width: 1.813333rem;
  height: 0.746667rem;
  border-radius: 0.413333rem;
  border: 0.026667rem solid #ffffff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.index .card .c-center .c-c-body .c-c-right .c-c-btn {
  font-size: 0.32rem;
  line-height: 0.44rem;
  color: #fff;
}

.index .card .c-footer span {
  color: #fff;
  font-size: 0.32rem;
  opacity: 0.8;
  line-height: 0.44rem;
}

.index .banner {
  height: 3.013333rem;
  background: #fff;
  box-sizing: border-box;
  padding: 0.213333rem 0.426667rem 0;
}

.index .banner .b-slide {
  width: 9.146667rem;
  height: 2.8rem;
}

.index .banner .b-slide .b-slide-box {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  height: 2.213333rem;
}

.index .banner .b-slide .b-slide-box img {
  display: block;
  max-height: 2.213333rem;
  width: 100%;
  object-fit: cover;
}

.index .banner .swiper-pagination {
  height: 0.586667rem;
  bottom: 0;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

.index .banner .swiper-pagination-bullet-active {
  background: #5D5D5D;
}

.index .recommend {
  height: 3.44rem;
  margin-bottom: 0.133333rem;
  /*推荐分期*/
}

.index .recommend .p-list {
  background-color: #FFFFFF;
}

.index .recommend .p-header {
  height: 0.973333rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0 0.426667rem 0.426667rem;
  box-sizing: border-box;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.index .recommend .p-header-h4 {
  font-size: 0.426667rem;
  color: #141E32;
  text-align: center;
  font-weight: 600;
  font-family: "PingFangSC-Regular";
  margin-right: 0.2rem;
}

.index .recommend .p-header-span {
  font-size: 0.32rem;
  color: #9e9e9e;
}

.index .recommend .p-list-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0 0.226667rem 0.226667rem;
  box-sizing: border-box;
}

.index .recommend .p-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 3.173333rem;
  height: 1.973333rem;
  box-sizing: border-box;
}

.index .recommend .p-item:nth-child(1) {
  background: url("../../assets/imgs/win/index/travle-back-v2.png") 0 0 no-repeat;
  background-size: 3.173333rem 1.973333rem;
}

.index .recommend .p-item:nth-child(2) {
  background: url("../../assets/imgs/win/index/shape-back-v2.png") 0 0 no-repeat;
  background-size: 3.173333rem 1.973333rem;
}

.index .recommend .p-item:nth-child(3) {
  background: url("../../assets/imgs/win/index/compucter-back-v2.png") 0 0 no-repeat;
  background-size: 3.173333rem 1.973333rem;
}

.index .recommend .p-item-left {
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
}

.index .recommend .p-item-left-h4 {
  font-size: 0.4rem;
  color: #141E32;
  line-height: 0.56rem;
  height: 0.56rem;
  position: relative;
  top: 0.32rem;
  left: 0.306667rem;
  font-weight: 500;
}

.index .recommend .p-item-left-span {
  display: block;
  font-size: 0.32rem;
  height: 0.44rem;
  position: relative;
  top: 0.426667rem;
  left: 0.306667rem;
  line-height: 0.44rem;
  color: #9e9e9e;
  margin: 0;
}

.index .recommend .p-item-right {
  width: 1.28rem;
  height: 1.28rem;
  padding-top: 0.533333rem;
}

.index .recommend .p-item-img-1 {
  display: block;
  margin: 0;
  width: 1.28rem;
  height: 1.28rem;
  background: url("../../assets/imgs/win/index/r-img-travle-new-v2.png") 0 0 no-repeat;
  background-size: 1.28rem 1.28rem;
}

.index .recommend .p-item-img-2 {
  display: block;
  margin: 0;
  width: 1.28rem;
  height: 1.28rem;
  background: url("../../assets/imgs/win/index/r-img-shape-new-v2.png") 0 0 no-repeat;
  background-size: 1.28rem 1.28rem;
}

.index .recommend .p-item-img-3 {
  display: block;
  margin: 0;
  width: 1.28rem;
  height: 1.28rem;
  background: url("../../assets/imgs/win/index/r-img-pc.png") 0 0 no-repeat;
  background-size: 1.28rem 1.28rem;
}

.index .footer {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  margin-top: 0.626667rem;
}

.index .footer .f-box {
  width: 4.386667rem;
  height: 1.773333rem;
  background: url("../../assets/imgs/win/index/i-footer-bg-sj.png") 0 0 no-repeat;
  background-size: 4.386667rem 1.773333rem;
  margin-bottom: 0.2rem;
}

.index .footer .f-box-tips {
  font-size: 0.266667rem;
  color: #ccc;
}

.index .test {
  padding: 0.266667rem;
}