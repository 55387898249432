.a-line {
  height: 1.733333rem;
  background: #fff;
  box-sizing: border-box;
  padding: 0 0.426667rem;
}

.a-line .a-inner {
  height: 100%;
  font-size: 0.453333rem;
  color: #212121;
  border-bottom: 1Px solid #F0F0F0;
  box-sizing: border-box;
  padding: 0;
}

.a-line .l-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.a-line .l-left .l-l-title {
  font-size: 0.426667rem;
  color: #212121;
  margin-right: 0.2rem;
}

.a-line .l-left .l-l-label {
  font-size: 0.32rem;
}

.a-line .l-left .l-l-label.l-label-danger {
  color: #FF4248;
}

.a-line .l-left .l-l-label.l-label-primary {
  color: #FFA130;
}

.a-line .l-left .l-l-label.l-label-default {
  color: #9E9E9E;
}

.a-line .l-right {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.a-line .l-right .l-right-money {
  font-size: 0.48rem;
  color: #212121;
  font-weight: bold;
  font-family: "FontDIN";
}

.a-line .l-right .l-right-money.l-right-grey {
  color: #9E9E9E;
}

.a-line .l-right .l-right-check {
  width: 0.64rem;
  height: 0.64rem;
  margin-left: 0.733333rem;
}

.a-line .l-right .l-right-check.check-true {
  background: url("../../assets/imgs/win/common/icon-c-true.png") 0 0 no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-line .l-right .l-right-check.check-false {
  background: url("../../assets/imgs/win/common/icon-c-false.png") 0 0 no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-line .l-right .l-right-check.check-disabled {
  background: url("../../assets/imgs/win/common/icon-c-disabled.png") 0 0 no-repeat;
  background-size: 0.64rem 0.64rem;
}

.a-line-primary {
  margin: 0.32rem 0;
}