.c-bank-list .m-bank-item {
  height: 1.6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.c-bank-list .m-bank-item .m-b-icon {
  width: 0.853333rem;
  height: 0.853333rem;
  margin-right: 0.426667rem;
}

.c-bank-list .m-bank-item .m-b-icon img {
  display: block;
  width: 100%;
}

.c-bank-list .m-bank-item .m-b-name {
  -webkit-flex: 1 1;
  flex: 1 1;
  color: #212121;
  font-size: 0.453333rem;
}

.c-bank-list .m-bank-item .m-b-status {
  width: 0.64rem;
  max-width: 0.64rem;
  height: 0.64rem;
}

.c-bank-list .m-bank-item .m-b-status img {
  display: block;
  width: 100%;
}

.c-bank-list .m-bank-item .m-b-status.m-b-status-new {
  width: 0.32rem;
  height: 0.64rem;
}