.nav-bar {
  position: fixed;
  width: 100%;
}

.bankResult-page {
  padding: 0.32rem 0;
}

.banks {
  width: 9.146667rem;
  height: 1.6rem;
  background: #fefefe;
  border-radius: 0.106667rem;
  margin: 0.466667rem auto;
}

.banks .banks-main {
  margin: 0.426667rem;
  line-height: 1.6rem;
}

.banks .banks-main .banks-img {
  display: inline-block;
  width: 0.746667rem;
  height: 0.746667rem;
  background: url(./imgs/bankicon.png) no-repeat;
  background-size: 0.746667rem 0.746667rem;
  vertical-align: middle;
  padding-right: 0.213333rem;
  text-align: center;
}

.banks .banks-main .banks-text {
  display: inline-block;
  font-size: 0.426667rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212121;
  vertical-align: middle;
  text-align: center;
}