.x-btn {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 1.306667rem;
  width: 100%;
}

.x-btn-inner {
  width: 9.146667rem;
  height: 1.306667rem;
  line-height: 1.306667rem;
  border-radius: 0.653333rem;
  font-size: 0.48rem;
  font-weight: 400;
  color: #333;
  text-align: center;
}

/*蓝色*/

.x-btn-primary {
  color: #fff;
  background: #FFA130;
}

/*禁用*/

.x-btn-disabled {
  color: #fff;
  background: #cccccc;
}