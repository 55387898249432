.m-modal {
  height: 10.24rem;
}

.m-modal.m-modal-header-center .am-modal-close {
  top: 0.48rem;
}

.m-modal.m-modal-header-center .am-modal-header {
  height: 1.6rem;
  line-height: 1.6rem;
  -webkit-justify-content: center;
  justify-content: center;
}

.m-modal.m-modal-header-center .am-modal-header .am-modal-title {
  font-size: 0.48rem;
  padding-left: 0;
  text-align: left;
}

.m-modal.m-modal-header-center .m-modal-body {
  height: 6.4rem !important;
  margin-bottom: 0.506667rem;
  overflow-y: auto;
}

.m-modal .am-modal-content {
  padding: 0;
  border-radius: 0;
  text-align: inherit;
}

.m-modal .am-modal-close {
  right: 0.4rem;
  top: 0.32rem;
  width: 0.64rem;
  height: 0.64rem;
  background: url("./imgs/i-modal-close.png") right center no-repeat;
  background-size: 0.64rem 0.64rem;
}

.m-modal .am-modal-close .am-modal-close-x {
  background: none;
}

.m-modal .am-modal-header {
  height: 1.28rem;
  line-height: 1.28rem;
  padding: 0 0.4rem;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 0.013333rem solid #F0F0F0;
}

.m-modal .am-modal-header .am-modal-title {
  color: #757575;
  font-size: 0.32rem;
  font-weight: 400;
}

.m-modal .am-modal-body {
  padding: 0 !important;
}

.m-modal .am-modal-body .m-modal-body {
  height: 8.96rem;
  overflow-y: auto;
}

.m-modal-body {
  position: relative;
  overflow-y: auto;
  padding: 0 0.426667rem !important;
}