.a-line {
  height: 1.6rem;
  background: #fff;
  box-sizing: border-box;
  padding-left: 0.4rem;
}

.a-line.a-line-icon {
  padding-left: 1.2rem;
}

.a-line .a-inner {
  height: 100%;
  font-size: 0.453333rem;
  color: #212121;
  border-bottom: 0.013333rem solid #F0F0F0;
  box-sizing: border-box;
  padding-right: 0.4rem;
}

.a-line .l-right {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.a-line .l-right p {
  color: #9E9E9E;
}

.a-line .l-right .l-r-txt {
  color: #212121;
}

.a-line .l-right svg {
  margin-right: -0.133333rem;
}